if(document.querySelectorAll(".fade-in").length > 0){
    setTimeout(() => {
        document.querySelectorAll(".fade-in:not(.is-visible)").forEach(function(element) {
            if(isElementXPercentInViewport(element, 5)) {
                element.classList.add("is-visible");
            }
        });
    },500);

    window.addEventListener('scroll', function(e) {
        document.querySelectorAll(".fade-in:not(.is-visible)").forEach(function(element) {
            if(isElementXPercentInViewport(element, 5)) {
                element.classList.add("is-visible");
            }
        });
    });

    function isElementXPercentInViewport(el, percentVisible) {
        let rect = el.getBoundingClientRect(),
            windowHeight = window.innerHeight;

        return !(
            Math.floor(100 - ((rect.top / -rect.height) * 100)) < percentVisible ||
            Math.floor(100 - ((rect.bottom - windowHeight) / rect.height) * 100) < percentVisible
        );
    }
}