if(document.getElementsByClassName("table_of_contents").length > 0){
    var contents = "<ul>";
    var count = 1;
    document.querySelectorAll("*:not(.table_of_content) > *[toc]").forEach(function(element) {
        var title = element.innerHTML;
        var link = "<a href='javascript:void(0)' onclick='scrollToElement(\"section-" + count + "\")'>" + title + "</a>";
        element.id = "section-" + count;
        contents += "<li>" + link + "</li>";
        count++;
    });
    contents += "</ul>";
    document.getElementsByClassName("table_of_contents")[0].innerHTML = contents;
}