require("./table-of-contents");
require("./same-height");
require("./is-visible");

import supportsWebP from 'supports-webp';
 
supportsWebP.then(supported => {
    var root = document.documentElement;
    if (supported) {
        root.classList.add("webp");
    }else{
        root.classList.add("no-webp");
    }
});

setTimeout(function(){
    document.getElementsByClassName('preloader')[0].classList.add('done');
    setTimeout(function(){
        document.getElementsByClassName('preloader')[0].style.display = 'none';
    }, 300);
}, 150);


document.getElementById("hamburger").addEventListener("click", toggleNavigation);

function toggleNavigation(){
    var nav = document.getElementsByTagName("nav")[0];
    var burger = document.getElementsByClassName("hamburger")[0];
    if(nav.classList.contains("active")){
        nav.classList.remove("active");
        burger.classList.remove("active");
    }else{
        nav.classList.add("active");
        burger.classList.add("active");
    }
}